<!--
 * @Description: 设置三级分类弹框
 * @Author: ChenXueLin
 * @Date: 2021-11-18 15:01:04
 * @LastEditTime: 2023-01-31 11:24:04
 * @LastEditors: ChenXueLin
-->
<template>
  <el-dialog
    v-dialogDrag
    title="选择物料"
    :visible="changeMaterialVisible"
    width="800px"
    :close-on-click-modal="false"
    :element-loading-background="loadingBackground"
    v-loading="dialogLoading"
    :before-close="handleClose"
    custom-class="createDispatchDialog"
  >
    <el-table :data="tableData" height="300px">
      <el-table-column
        show-overflow-tooltip
        v-for="(column, index) in columnData"
        :key="index"
        :prop="column.fieldName"
        :label="column.fieldLabel"
        :min-width="column.width"
        :fixed="column.fixed"
        :align="column.align"
        header-align="center"
      >
        <template slot-scope="{ row }">
          <span
            v-if="column.fieldName === 'materialsNoList' && !isGrayscaleWork"
          >
            <e6-vr-select
              v-model="row.materialsNoList"
              :data="row.thirdList"
              placeholder="物料名称"
              title="物料名称"
              clearable
              :props="{
                id: 'materialNo',
                label: 'materialName'
              }"
              multiple
            ></e6-vr-select>
          </span>
          <span v-else>{{ row[column.fieldName] }}</span>
        </template>
      </el-table-column>
    </el-table>
    <div class="dialog-footer" slot="footer">
      <el-button class="cancel" @click="handleClose">取消</el-button>
      <el-button type="primary" @click="handleSubmit"> 确定</el-button>
    </div>
  </el-dialog>
</template>

<script>
import base from "@/mixins/base";
import {
  getProductList,
  setMaterial,
  getListByIds,
  getWorkProductMaterial
} from "@/api";
import { printError } from "@/utils/util";
export default {
  name: "changeMaterial",
  components: {},
  data() {
    return {
      tableData: [], // 表格数据
      dialogLoading: false
    };
  },
  props: ["changeMaterialVisible", "itemIdList", "taskId", "isGrayscaleWork"],
  mixins: [base],
  computed: {
    columnData() {
      let columnData = !this.isGrayscaleWork
        ? [
            {
              fieldName: "materialsNoList",
              display: true,
              fieldLabel: "物料名称",
              width: 160,
              disabled: false,
              fixed: false,
              align: "center"
            },
            {
              fieldName: "firstClassName",
              display: true,
              fieldLabel: "商品分类",
              width: 160,
              disabled: false,
              fixed: false,
              align: "center"
            },
            {
              fieldName: "secondClassName",
              display: true,
              fieldLabel: "商品开票名称",
              width: 160,
              disabled: false,
              fixed: false,
              align: "center"
            },
            {
              fieldName: "equipType",
              display: true,
              fieldLabel: "设备类型",
              width: 160,
              disabled: false,
              fixed: false,
              align: "center"
            }
          ]
        : [
            {
              fieldName: "materialName",
              display: true,
              fieldLabel: "物料名称",
              width: 160,
              disabled: false,
              fixed: false,
              align: "center"
            },
            {
              fieldName: "materialNo",
              display: true,
              fieldLabel: "物料编码",
              width: 100,
              disabled: false,
              fixed: false,
              align: "center"
            },
            {
              fieldName: "allAmount",
              display: true,
              fieldLabel: "安装数量",
              width: 100,
              disabled: false,
              fixed: false,
              align: "center"
            },
            {
              fieldName: "firstClassName",
              display: true,
              fieldLabel: "商品分类",
              width: 120,
              disabled: false,
              fixed: false,
              align: "center"
            },
            {
              fieldName: "secondClassName",
              display: true,
              fieldLabel: "商品开票名称",
              width: 160,
              disabled: false,
              fixed: false,
              align: "center"
            },
            {
              fieldName: "materialTypeName",
              display: true,
              fieldLabel: "设备类型",
              width: 130,
              disabled: false,
              fixed: false,
              align: "center"
            }
          ];
      return columnData;
    }
  },
  watch: {
    changeMaterialVisible(val) {
      if (val) {
        if (this.isGrayscaleWork) {
          this.getWorkProductMaterialReq();
        } else {
          this.getProductListReq();
        }
      }
    }
  },
  created() {},
  methods: {
    //获取设备明细
    async getProductListReq() {
      try {
        this.dialogLoading = true;
        let res = await getProductList({
          taskId: this.taskId,
          itemId: this.itemIdList[0]
        });
        //客户名称下拉框
        let tableData = res.data ? res.data : [];
        tableData.map(item => {
          item.materialsNoList = item.materialsList.length
            ? item.materialsList.map(item => item.materialsNo)
            : [];
          item.materialsList = [];
          item.thirdList = [];
        });
        let secondIds = tableData.map(item => {
          return item.productId;
        });
        this.tableData = tableData;
        if (this.tableData.length) {
          this.getProductListListReq(secondIds);
        }
      } catch (error) {
        printError(error);
      } finally {
        this.dialogLoading = false;
      }
    },
    //获取灰度客户的物料列表
    async getWorkProductMaterialReq() {
      try {
        this.dialogLoading = true;
        let res = await getWorkProductMaterial({
          taskId: this.taskId,
          isMerge: false
        });
        let tableData = res.data;
        tableData.map(item => {
          item.productId =
            item.returnType == 1 ? item.businessId : item.sourceProductCode;
          item.productNumber = item.businessAmount;
          item.materialsList = [];
        });
        this.tableData = tableData;
      } catch (error) {
        printError(error);
      } finally {
        this.dialogLoading = false;
      }
    },
    //获取产品名称
    async getProductListListReq(productIds) {
      try {
        let res = await getListByIds({ productIds });
        let list = res.data;
        let tableData = this.tableData;
        tableData.map(item => {
          item.thirdList = list.find(
            v => v.productCode == item.productId
          ).materialDetails;
        });
        this.tableData = tableData;
      } catch (error) {
        printError(error);
      } finally {
        this.dialogLoading = false;
      }
    },
    //设置物料名称
    async setMaterialReq() {
      try {
        this.dialogLoading = true;
        let tableData = this.tableData;
        let productList;
        if (this.isGrayscaleWork) {
          tableData.map(item1 => {
            item1.materialsList = tableData
              .filter(item2 => item2.productId == item1.productId)
              .map(ele => {
                return Object.assign(
                  {},
                  {
                    materialsNo: ele.materialNo,
                    usedCount: ele.useAmount,
                    materialsName: ele.materialName,
                    allAmount: ele.allAmount
                  }
                );
              });
          });
          productList = tableData.map(item => {
            return Object.assign(
              {},
              {
                materialsList: item.materialsList,
                productId: item.productId,
                productNumber: item.productNumber
              }
            );
          });
          let obj = {};
          productList = productList.reduce((newArr, next) => {
            obj[next.productId]
              ? ""
              : (obj[next.productId] = true && newArr.push(next));
            return newArr;
          }, []);
        } else {
          //旧版本
          tableData.map(item => {
            item.materialsNoList.map(ele => {
              let obj = item.thirdList.find(v => v.materialNo == ele);
              if (obj.materialNo) {
                item.materialsList.push(obj);
              }
            });
          });
          productList = tableData.map(item => {
            return Object.assign(
              {},
              {
                materialsList: item.materialsList,
                productId: item.productId,
                productNumber: item.productNumber
              }
            );
          });
        }
        let reqData = this.isGrayscaleWork
          ? {
              taskId: this.taskId,
              productList
            }
          : {
              itemId: this.itemIdList[0],
              productList
            };
        let res = await setMaterial(reqData);
        if (res.code == "OK") {
          this.$message.success("设置成功");
          this.$emit("refresh");
          this.handleClose();
        }
      } catch (error) {
        printError(error);
      } finally {
        this.dialogLoading = false;
      }
    },
    //点击确定新增
    handleSubmit() {
      if (
        !this.isGrayscaleWork &&
        !this.tableData.every(item => item.materialsNoList.length)
      ) {
        this.$message.warning("请选择物料名称");
        return;
      }
      if (this.tableData.length) {
        this.setMaterialReq();
      } else {
        this.handleClose();
      }
    },
    //关闭弹框
    handleClose() {
      this.$emit("handleClose", "changeMaterialVisible");
    }
  }
};
</script>
<style lang="scss" scoped>
/deep/.createDispatchDialog {
  .el-dialog__body {
    padding-top: 20px;
  }
}
</style>
